import { useEffect } from 'react'

import { ROUTES } from '../../../resources/routes-constants'
import { API } from '../../../resources/api-constants';

import { useLocation } from 'react-router-dom';

import { clientAxios } from 'utility/api/agent/axiosAgent';


function TopNavbarVerificationAgent() {
    const location = useLocation();
    function init() {
        const userSession = localStorage.getItem('user-session');
        if (userSession !== API.AGENT_CODE) {
            window.location.href = ROUTES.AGENT_LOGIN_ROUTE;
        }

        const loggedInUser = localStorage.getItem('access-token');

        if (!loggedInUser) { // not logged in, redirect
            localStorage.clear();
            window.location.href = ROUTES.AGENT_LOGIN_ROUTE;
        }

        //GET TOTAL CREDITS
        clientAxios.get(
            API.AGENT_ACCOUNT_LINK,
            {}
        ).then((response) => {
            if (response.data.data.verified === true)
                window.location.replace(ROUTES.AGENT_HOME_ROUTE);

            if ('' === response.data.data.mobileNumber) {
                if (location.pathname !== ROUTES.AGENT_MOBILE_UPDATE_ROUTE)
                    window.location.replace(ROUTES.AGENT_MOBILE_UPDATE_ROUTE);
            } else {
                //redirect to validate mobile if mobile is not yet confirmed

                if (response.data.data.verified === false) {
                    if (location.pathname !== ROUTES.AGENT_VALIDATE_ROUTE)
                        window.location.replace(ROUTES.AGENT_VALIDATE_ROUTE);
                }
            }

        }).catch((error) => {
            if ('INVALID_TOKEN' === error?.response?.data?.result) {
                localStorage.clear();

                window.location.href = ROUTES.LOGIN_ROUTE;
            }
        });

        //END GET TOTAL CREDITS
    }


    useEffect(() => {
        init();
    }, []);

    return (
        <></>
    );
}

export default TopNavbarVerificationAgent;