import React, { useState } from 'react';
import { API } from 'resources/api-constants';
import { ROUTES } from 'resources/routes-constants';
import { clientAxios } from 'utility/api/agent/axiosAgent';

const useAgentValidateMobileLogic = () => {
    const [errorMessage, setErrorMessage] = useState('');

    //for otp counter
    const initialCountdown = 45; //45 seconds
    const [counter, setCounter] = useState(0);
    const [isResendDisabled, setIsResendDisabled] = useState(false);

    const handleSubmit = async function (e: any) {
        e.preventDefault();

        //code
        const code1 = e.target.code1.value;
        const code2 = e.target.code2.value;
        const code3 = e.target.code3.value;
        const code4 = e.target.code4.value;
        const code5 = e.target.code5.value;
        const code6 = e.target.code6.value;

        const code = code1 + code2 + code3 + code4 + code5 + code6;

        //call api
        await clientAxios.post(
            API.AGENT_OTP_VERIFY_LINK,
            {
                otp: code
            }
        ).then((response) => {
            setErrorMessage('Verifying');
            if (response.data.data.result === 'OK') {
                setErrorMessage('');

                window.location.replace(ROUTES.AGENT_HOME_ROUTE);

            } else {
                setErrorMessage('An error has occured. Try again later.');
            }

        }).catch((error) => {
            //setErrorMessage('An error has occured. Try again later.');
            setErrorMessage(error.response.data.resource);
        }).finally(() => {

        });
    }

    const requestOTP = function () {
        const newTime = Date.now() + (initialCountdown * 1000); 
        localStorage.setItem("savedOTPcounter", "" + newTime); 
        setIsResendDisabled(true);
        setCounter(initialCountdown);

        clientAxios.post(
            API.AGENT_OTP_GENERATE_LINK,
            {
            }
        ).then((response) => {
            //setErrorMessage('Sending email...');
        }).catch((error) => {
            setErrorMessage('An error has occured. Try again later.');
        }).finally(() => {

        });
    }

    const checkSavedTime = function () {
        // Check for a saved timer in localStorage
        const savedTime = localStorage.getItem("savedOTPcounter");
        if (savedTime) {
            const remainingTime = Math.max(
                Math.floor((parseInt(savedTime, 10) - Date.now()) / 1000),
                0
            );
            if (remainingTime > 0) {
                setCounter(remainingTime);
                setIsResendDisabled(true);
            }
        } else {
            // If no saved time, start with the initial countdown
            setCounter(initialCountdown);
            setIsResendDisabled(true);
            const newTime = Date.now() + initialCountdown * 1000;
            localStorage.setItem("savedOTPcounter", "" + newTime);
        }
    }

    React.useEffect(() => {
        if (localStorage.getItem('agent-send-otp') === '1') {
            localStorage.setItem('agent-send-otp', '0');
            //request OTP
            requestOTP();
        }

        //for OTP reset
        checkSavedTime();
    }, []);

    React.useEffect(() => {//for OTP reset
        if (isResendDisabled && counter > 0) {
            var timer = setInterval(() => {
                setCounter((prevCounter) => {
                    const newCounter = prevCounter - 1;
                    
                    if (newCounter <= 0) {
                        setIsResendDisabled(false);
                        localStorage.removeItem("savedOTPcounter");
                        localStorage.removeItem("savedOTPcounter");
                    }
                    
                    return newCounter;
                });
            }, 1000);
        }

        return () => clearInterval(timer); // Cleanup on unmount
        

    }, [counter, isResendDisabled]);


    return {
        counter, isResendDisabled,
        handleSubmit, requestOTP,
        errorMessage
    };
}

export default useAgentValidateMobileLogic;