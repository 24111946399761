import { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

import { ROUTES } from '../../../resources/routes-constants'
import { API } from '../../../resources/api-constants';

import { clientAxios } from 'utility/api/members/axiosPlayer';

function TopNavBarVerification() {
    const location = useLocation();

    function init() {
        const userSession = localStorage.getItem('user-session');
        if (userSession !== API.PLAYER_CODE) {
            window.location.href = ROUTES.LOGIN_ROUTE;
        }

        const loggedInUser = localStorage.getItem('access-token');

        if (!loggedInUser) { // not logged in, redirect
            localStorage.clear();
            window.location.href = ROUTES.LOGIN_ROUTE;
        }
        
       clientAxios.get(
            API.PLAYER_ACCOUNT_LINK,
            {}
       ).then((response) => {
           
           if (response.data.data.verified === true)
               window.location.replace(ROUTES.HOME_ROUTE);

           if ('' === response.data.data.mobileNumber) {
               if (location.pathname !== ROUTES.MOBILE_UPDATE_ROUTE)
                    window.location.replace(ROUTES.MOBILE_UPDATE_ROUTE);
           } else {
               //redirect to validate mobile if mobile is not yet confirmed

               if (response.data.data.verified === false) {
                   if (location.pathname !== ROUTES.VALIDATE_ROUTE)
                        window.location.replace(ROUTES.VALIDATE_ROUTE);
               }
               else if (null === response.data.data.lastLogin || 'null' === response.data.data.lastLogin)
                   window.location.replace(ROUTES.TUTORIAL_ROUTE);
           }
        }).catch((error) => {
            if ('INVALID_TOKEN' === error?.response?.data?.result) {
                localStorage.clear();

                window.location.href = ROUTES.LOGIN_ROUTE;
            }
        });
    }

    useEffect(() => {
        init();
        
    }, []);

    return (
        <></>
    );
}

export default TopNavBarVerification;