import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API } from 'resources/api-constants';
import { clientAxios } from 'utility/api/members/axiosPlayer';
import { getNumber } from 'utility/functions';
import { ROUTES } from '../../resources/routes-constants';

const useWithdrawLogic = () => {
    const [show, setShow] = useState(false);

    const [transaction, setTransaction] = useState('withdraw');
    const handleClose = () => setShow(false);

    const [errorMessage, setErrorMessage] = useState('');
    const [error, setError] = useState('');

    const [amount, setAmount] = useState(0);
    const [gcashName, setGcashName] = useState('');
    const [gcash, setGcash] = useState('');

    const handleShow = (e: any) => {
        e.preventDefault();

        setError('');
        const gcashAmount = getNumber(e.target.amount.value);

        if(gcashAmount < 200) {
            setError('Minimum amount is 200')
            return null;
        } else if(gcashAmount > 50000) {
            setError('Maximum amount is 50,000')
            return null;
        } else if(gcashAmount > Number(localStorage.getItem('total-credits'))) {
            setError('Amount must be less than or equal to your current balance')
            return null;
        }

        setAmount(gcashAmount);
        setGcashName(e.target.gcashName.value);
        setGcash(e.target.gcash.value);

        setShow(true);
    };
    //form handling

    //submit gcash request
    const homeRedirect = useNavigate();
    const [withdrawSubmitted, setWithdrawSubmitted] = useState(false);
    const handleSubmit = (gcash: any, amount: any, buttonRef: any) => async (e: any) => {
        e.preventDefault();
        setShow(false);

        const emailAddress = localStorage.getItem('email-address');

        buttonRef.current.disabled = true;

        await clientAxios.post(
            API.PLAYER_WITHDRAW_REQUEST_LINK,
            {
                email: emailAddress,
                gcashNo: gcash,
                gcashName: gcashName,
                amount: amount
            }
        ).then((response) => {
            // setErrorMessage('Submitt');
            // setWithdrawSubmitted(true);
            setErrorMessage('Withdraw request sent.');
            setAmount(0);
            setGcash('');

            localStorage.setItem('withdraw-credit', '0');
        }).catch((error) => {
            setErrorMessage('An error has occured. Try again later.');
            setWithdrawSubmitted(false);
        }).finally(() => {
            homeRedirect(ROUTES.PLAY_ROUTE);
        });
    }

    //yes to details and send verification email
    const verificationRedirect = useNavigate();
    const [sendEmailStatus, setSendEmailStatus] = useState(false);
    const handleVerifyEmail = async function (e: any) {
        e.preventDefault();

        setErrorMessage('Sending OTP...');
        setSendEmailStatus(true);

        clientAxios.post(
            API.PLAYER_OTP_GENERATE_LINK,
            {
            }
        ).then((response) => {
            setErrorMessage('Sending OTP...');
        }).catch((error) => {
            setErrorMessage('An error has occured. Try again later.');
        }).finally(() => {
            //redirect to verification page        
            setSendEmailStatus(false);
            verificationRedirect(ROUTES.VERIFY_ROUTE);
        });
    }

    return {
        show, handleClose, handleSubmit, transaction,
        handleShow,
        handleVerifyEmail, errorMessage, sendEmailStatus,
        amount, gcash, withdrawSubmitted, gcashName,
        error
    };
}

export default useWithdrawLogic;