import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API } from 'resources/api-constants';
import { ROUTES } from 'resources/routes-constants';
import { clientAxios, refreshToken } from 'utility/api/members/axiosPlayer';

const useAccountLogic = () => {
    const [show, setShow] = useState(false);
    const [showToast, setShowToast] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');

    const transaction = () => 'verify';
    const handleClose = () => setShow(false);
    const handleSubmit = function () {
        setShow(false);
        setShowToast(true);
    }

    const [errorMessageName, setErrorMessageName] = useState('');
    const handleUpdateName = async (e: any) => {
        e.preventDefault();
        // handleShow();
        const fullname = e.target.fullname.value;

        try {
            // updateErrorMessage("Logging In...");

            const response = await clientAxios.post(
                API.PLAYER_UPDATE_NAME_LINK,
                {
                    name: fullname
                }
            );

            localStorage.setItem('name', response.data.data.name);

            //update name text
            const nameObj = document.getElementById('fullName');
            Object(nameObj).innerHTML = response.data.data.name;

            setErrorMessageName(response.data.message);


        } catch (error: any) {

            setErrorMessageName("An error has occured. Please try again.");
        }
    }

    //CHANGE PASSWORD
    const handleChangePassword = async (e: any) => {
        e.preventDefault();

        const password = e.target.password.value;
        const newpassword = e.target.newpassword.value;
        const verifypassword = e.target.verifypassword.value;

        if (newpassword == verifypassword) {
            //call API

            try {

                const response = await clientAxios.patch(
                    API.PLAYER_RESET_PASSWORD_LINK,
                    {
                        currentPassword: password,
                        newPassword: newpassword,
                        verifyPassword: verifypassword
                    },
                    {
                        headers: { "Content-Type": "application/json" },
                    }
                );

                setErrorMessage(response.data.data.message);
            } catch (error: any) {
                if (!error.response) {
                    setErrorMessage("No Server Response");
                } else if (error.response.status === 422) {
                    setErrorMessage(error.response.data.resource[0].msg);
                } else if (error.response.status === 401) {
                    setErrorMessage("User not found.");
                } else {
                    setErrorMessage("Update Failed. Try again.");
                }

            }

        } else {
            setErrorMessage("Passwords don't match.");
        }

    }
    //END CHANGE PASSWORD

    const handleShow = () => setShow(true);
    const handleCloseToast = () => setShowToast(false);

    const [eyeicon, seteye] = useState(true);
    const [password, setpassword] = useState("password");

    const [eyeicon2, seteye2] = useState(true);
    const [password2, setpassword2] = useState("password");

    const [eyeicon3, seteye3] = useState(true);
    const [password3, setpassword3] = useState("password");

    const Eye = (passType: any) => {
        if (1 == passType) {
            if (password == "password") {
                setpassword("text");
                seteye(false);
            }
            else {
                setpassword("password");
                seteye(true);
            }
        } else if (2 == passType) {
            if (password2 == "password") {
                setpassword2("text");
                seteye2(false);
            }
            else {
                setpassword2("password");
                seteye2(true);
            }
        } else {

            if (password3 == "password") {
                setpassword3("text");
                seteye3(false);
            }
            else {
                setpassword3("password");
                seteye3(true);
            }
        }
    }

    //VERIFICATION EMAIL
    const verificationRedirect = useNavigate();
    const handleVerifyEmail = async function (e: any) {
        e.preventDefault();

        const emailAddress = localStorage.getItem('email-address');

        clientAxios.post(
            API.PLAYER_OTP_GENERATE_LINK,
            {
                email: emailAddress,
                appEmail: API.APP_EMAIL_ADDRESS
            }
        ).then((response) => {
            setErrorMessage('Sending email...');
        }).catch((error) => {
            setErrorMessage('An error has occured. Try again later.');
        }).finally(() => {
            //redirect to verification page        
            verificationRedirect(ROUTES.VERIFY_ROUTE);
        });
    }
    //END VERIFICATION EMAIL

    return {
        Eye,
        showToast,
        handleCloseToast,
        show,
        handleClose,
        handleSubmit,
        transaction,
        handleUpdateName,
        handleChangePassword,
        password, eyeicon,
        password2, eyeicon2,
        password3, eyeicon3,
        errorMessage, errorMessageName,
        handleVerifyEmail
    };
}

export default useAccountLogic;