import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../resources/routes-constants';


function useLogoutLogic() {
    const homeRedirect = useNavigate();

    useEffect(() => {
        localStorage.clear();
        homeRedirect(ROUTES.LOGIN_ROUTE);
    }, []);

};

export default useLogoutLogic;