import { useLocation } from 'react-router-dom';

import AppContent from './AppContent'
import TopNavBar from 'pages/partials/members/TopNavbar'
import TopNavBarVerification from 'pages/partials/members/TopNavBarVerification'
import { ROUTES } from '../resources/routes-constants';

const DefaultLayout = () => {
    const location = useLocation();

    return (
        <div>
            {
            (location.pathname !== ROUTES.VALIDATE_ROUTE && location.pathname !== ROUTES.MOBILE_UPDATE_ROUTE) ?
                <TopNavBar />
            :
                <TopNavBarVerification />
            }
      
        <div className="body flex-grow-1">
            <AppContent />
        </div>
    </div>
    )
}

export default DefaultLayout