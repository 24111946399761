import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'

import Form from 'react-bootstrap/Form';
import CustomToast from '../../components/members/CustomToast';

import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../resources/routes-constants';
import { clientAxios } from 'utility/api/members/axiosPlayer';
import { API } from 'resources/api-constants';
import { clearInputValue, focusNextInput } from 'utility/functions';

const VerifyEmail: React.FC = () => {
    const [showToast, setShowToast] = useState(false);
    const [showToastType, setShowToastType] = useState('success');

    const [errorMessage, setErrorMessage] = useState('');

    const handleCloseToast = () => setShowToast(false);

    //const verificationRedirect = useNavigate();
    const [result, setResult] = React.useState(false);
    const [code, setCode] = useState('');


    const handleSubmit = async function (e: any) {
        e.preventDefault();

        //code
        const code1 = e.target.code1.value;
        const code2 = e.target.code2.value;
        const code3 = e.target.code3.value;
        const code4 = e.target.code4.value;
        const code5 = e.target.code5.value;
        const code6 = e.target.code6.value;

        const code = code1 + code2 + code3 + code4 + code5 + code6;
        const emailAddress = localStorage.getItem('email-address');

        //call api
        await clientAxios.post(
            API.PLAYER_OTP_VERIFY_LINK,
            {
                otp: code
            }
        ).then((response) => {
            setErrorMessage('Verifying');
            if (response.data.data.result === 'OK') {
                setShowToastType('success');
                setErrorMessage('');
                setResult(true);
                localStorage.setItem('user-verified', '1'); //email verified
                localStorage.setItem('withdraw-credit', '1'); //user can withdraw
            } else {
                setShowToastType('fail');
                setErrorMessage('An error has occured. Try again later.');
                setResult(false);
            }

        }).catch((error) => {
            //setErrorMessage('An error has occured. Try again later.');
            setErrorMessage(error.response.data.resource);
            setResult(false);
        }).finally(() => {

        });
    }

    //for otp counter
    const initialCountdown = 45; //45 seconds
    const [counter, setCounter] = useState(0);
    const [isResendDisabled, setIsResendDisabled] = useState(false);

    const requestOTP = function () {
        const newTime = Date.now() + (initialCountdown * 1000);
        localStorage.setItem("savedOTPcounter", "" + newTime);
        setIsResendDisabled(true);
        setCounter(initialCountdown);

        clientAxios.post(
            API.PLAYER_OTP_GENERATE_LINK,
            {
            }
        ).then((response) => {
            //setErrorMessage('Sending email...');
            console.log(response);
        }).catch((error) => {
            setErrorMessage('An error has occured. Try again later.');
        }).finally(() => {

        });
    }

    const checkSavedTime = function () {
        // Check for a saved timer in localStorage
        const savedTime = localStorage.getItem("savedOTPcounter");
        if (savedTime) {
            const remainingTime = Math.max(
                Math.floor((parseInt(savedTime, 10) - Date.now()) / 1000),
                0
            );
            if (remainingTime > 0) {
                setCounter(remainingTime);
                setIsResendDisabled(true);
            }
        } else {
            // If no saved time, start with the initial countdown
            setCounter(initialCountdown);
            setIsResendDisabled(true);
            const newTime = Date.now() + initialCountdown * 1000;
            localStorage.setItem("savedOTPcounter", "" + newTime);
        }
    }

    React.useEffect(() => {
        if (localStorage.getItem('send-otp') === '1') {
            //request OTP
            requestOTP();
            localStorage.setItem('send-otp', '0');
        }

        //for OTP reset
        checkSavedTime();
    }, []);

    React.useEffect(() => {//for OTP reset
        if (isResendDisabled && counter > 0) {
            var timer = setInterval(() => {
                setCounter((prevCounter) => {
                    const newCounter = prevCounter - 1;
                    console.log(newCounter);

                    if (newCounter <= 0) {
                        setIsResendDisabled(false);
                        localStorage.removeItem("savedOTPcounter");
                        console.log(newCounter, isResendDisabled);
                    }

                    return newCounter;
                });
            }, 1000);
        }

        return () => clearInterval(timer); // Cleanup on unmount


    }, [counter, isResendDisabled]);

    return (
        <>
            <CustomToast show={showToast} close={handleCloseToast} showToastType={showToastType} />
            <Container>
                <Row>
                    <Col
                        lg={{ span: 6, offset: 3 }}
                        md={{ span: 8, offset: 2 }}
                        className="text-center">
                        <h4>One final step!</h4>
                    </Col>
                </Row>
                <Row>
                    <Col
                        lg={{ span: 6, offset: 3 }}
                        md={{ span: 8, offset: 2 }}
                        className='p-5 text-center'>
                        <h6>Please check your phone for verification code</h6>
                    </Col>
                </Row>
                <Row>
                    <Col
                        lg={{ span: 6, offset: 3 }}
                        md={{ span: 8, offset: 2 }}
                        className='boxContainer p-5 text-center'>
                        {('1' === localStorage.getItem('withdraw-credit')) ?
                            <>
                                <h4 className='text-success'>Mobile Number Verified!</h4>
                                <div className='mb-2 mt-4'><a href={ROUTES.WITHDRAW_ROUTE}>Click here to withdraw</a></div>
                                <div><a href={ROUTES.ACCOUNT_ROUTE}>Click here to update account</a></div>
                            </>
                            :
                            <>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className="mb-2 text-center " >
                                        <Form.Label ><h6 >Enter Code</h6></Form.Label>
                                        <div className='codeContainer'>
                                            <input type="number" name='code1' id='code1'
                                                className='p-1 text-center me-2 rounded-2 h5' onFocus={clearInputValue} onChange={focusNextInput('code2')} size={1} maxLength={1} style={{ width: '30px' }} autoFocus required />
                                            <input type="number" name='code2' id='code2'
                                                className='p-1 text-center me-2 rounded-2 h5' onFocus={clearInputValue}  onChange={focusNextInput('code3')} size={1} maxLength={1} style={{ width: '30px' }} required />
                                            <input type="number" name='code3' id='code3'
                                                className='p-1 text-center me-2 rounded-2 h5' onFocus={clearInputValue} onChange={focusNextInput('code4')} size={1} maxLength={1} style={{ width: '30px' }} required />
                                            <input type="number" name='code4' id='code4'
                                                className='p-1 text-center me-2 rounded-2 h5' onFocus={clearInputValue} onChange={focusNextInput('code5')} size={1} maxLength={1} style={{ width: '30px' }} required />
                                            <input type="number" name='code5' id='code5'
                                                className='p-1 text-center me-2 rounded-2 h5' onFocus={clearInputValue} onChange={focusNextInput('code6')} size={1} maxLength={1} style={{ width: '30px' }} required />
                                            <input type="number" name='code6' id='code6'
                                                className='p-1 text-center rounded-2 h5' onFocus={clearInputValue} onChange={focusNextInput('verify')} size={1} maxLength={1} style={{ width: '30px' }} required />
                                        </div>
                                        {/* <div className='text-end'>
                                            <Form.Text className="justify-text-right "><a href='#' className='h6'>Resend Code</a></Form.Text>
                                        </div> */}
                                        <Button className="mt-4 w-50" type="submit" id='verify'>Verify</Button>
                                    </Form.Group>
                                </Form>
                                <p className='text-danger mt-4 mb-0 pb-0'>&nbsp;{errorMessage}</p>
                                <h6 className='mt-4'>
                                    Didn't receive the code?<br />
                                    {isResendDisabled ? <small>Please wait {counter} seconds.</small> : <a href="#" onClick={requestOTP}>Resend OTP</a>}</h6>
                            </>
                        }
                    </Col>
                </Row>
            </Container >
        </ >
    );
}

export default VerifyEmail;