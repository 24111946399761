import React, { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../resources/routes-constants';

import { clientAxiosLoginPlayer } from 'utility/api/members/axiosPlayer';

import { API } from 'resources/api-constants';

//JS Fingerprint
import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';

const useLoginLogic = () => {
    const initialFormData = Object.freeze({
        username: "",
        password: "",
        remember: "",
    });

    const [formData, updateFormData] = React.useState(initialFormData);
    const [loadingStatus, setLoadingStatus] = useState(true);

    const handleChange = (e: any) => {
        updateFormData({
            ...formData,

            // Trimming any whitespace
            [e.target.name]: e.target.value.trim()
        });
    };

    const homeRedirect = useNavigate();

    const [errorMessage, updateErrorMessage] = React.useState('');

    const handleSubmit = (e: any) => {
        e.preventDefault();

        loginUser(e);
    };

    function loginUser(e: any) {
        // try {
        updateErrorMessage("Logging In...");

        //clear everything first
        localStorage.clear();

        const rememberMe = e.target.remember.checked;

        clientAxiosLoginPlayer.post(
            API.LOGIN_LINK,
            {
                username: formData.username,
                password: formData.password,
                //deviceID: browserFingerPrint,
            }
        ).then((response) => {
            if (response.data.data.role.title === 'Player') {
                
                updateErrorMessage('Login Successful');
                //successful
               
                localStorage.setItem('validToken', 'true');
                
                localStorage.setItem('access-token', response.data.data.accessToken);

                localStorage.setItem('login-date', String(Date.now()));
                localStorage.setItem('user-id', response.data.data.id);
                localStorage.setItem('email-address', response.data.data.email);
                localStorage.setItem('mobile-number', response.data.data.mobileNumber);
                localStorage.setItem('name', response.data.data.name);

                if (response.data.data.verified === false) {
                    localStorage.setItem('user-verified', '0');
                } else {
                    localStorage.setItem('user-verified', '1');
                }
                
                if (rememberMe) {
                    localStorage.setItem('user-remember', '1');
                } else {
                    localStorage.setItem('user-remember', '0');
                }
                
                localStorage.setItem('total-credits', response.data.data.wallet.balance);

                localStorage.setItem('withdraw-credit', '0');

                localStorage.setItem('user-session', API.PLAYER_CODE);
                localStorage.setItem('send-otp', '0');

                //check if mobile number exists
                if ('' === response.data.data.mobileNumber) {
                    window.location.replace(ROUTES.MOBILE_UPDATE_ROUTE);
                } else {       
                    ////redirect to validate mobile if mobile is not yet confirmed
                    if (response.data.data.verified === false) {
                        localStorage.setItem('send-otp', '1');
                        window.location.replace(ROUTES.VALIDATE_ROUTE);
                    }
                    else if (null === response.data.data.lastLogin || 'null' === response.data.data.lastLogin)
                        window.location.replace(ROUTES.TUTORIAL_ROUTE);
                    else
                        window.location.replace(ROUTES.HOME_ROUTE);
                }

            } else {
                updateErrorMessage("Login Failed.");
                localStorage.clear();
            }
        }).catch((error) => {
            updateErrorMessage("Login Failed.");
            localStorage.clear();
        }).finally(() => {
            // homeRedirect(ROUTES.HOME_ROUTE);
        });

        //getCurrentBrowserFingerPrint().then((fingerprint) => { // Browser Fingerprint
        //    // fingerprint is your unique browser id.
        //    // This is well tested
        //    var browserFingerPrint = fingerprint;
        //    localStorage.setItem('deviceID', browserFingerPrint);
        //    // the result you receive here is the combination of Canvas fingerprint and audio fingerprint.


        //});
    }

    const [eyeicon, seteye] = useState(true);
    const [password, setpassword] = useState("password");

    function Eye() {
        if (password == "password") {
            setpassword("text");
            seteye(false);
        }
        else {
            setpassword("password");
            seteye(true);
        }
    }

    //fetch on initial load
    React.useEffect(() => {
        if (localStorage.getItem('user-remember') === '1' && localStorage.getItem('access-token')) {
            window.location.replace(ROUTES.HOME_ROUTE);
        } else {
            setLoadingStatus(false);
        }
    }, []);

    return {
        handleSubmit, handleChange,
        password, eyeicon,
        Eye,
        errorMessage,
        loadingStatus
    };
}

export default useLoginLogic;