import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { ROUTES } from 'resources/routes-constants';

import useAgentValidateMobileLogic from '../../components/agents/AgentValidateMobileLogic';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'

import { clearInputValue, focusNextInput } from 'utility/functions';

const AgentValidateMobile: React.FC = () => {
    const {
        counter, isResendDisabled,
        handleSubmit, requestOTP,
        errorMessage
    } = useAgentValidateMobileLogic();

    return (
        <>
            <Container>
                <Row className='mt-5'>
                    <Col
                        lg={{ span: 4, offset: 4 }}
                        className="text-center mb-5">
                        <h1 className='mb-4'>Validate <span className='highlightText px-2'>Mobile</span></h1>
                        <p className='h5'>Validate your mobile number to proceed.</p>
                        <p className='h5'>We've sent a One-Time Password (OTP) through your registered mobile number.</p>
                        {/*<br />*/}
                        {/*<p className='h6'>Enter the 6-digit code sent to your mobile number:</p>*/}
                    </Col>
                </Row>
                <Row>
                    <Col
                        lg={{ span: 4, offset: 4 }}
                        style={{ minHeight: '' }}
                        className='boxContainer p-5 text-center'>

                        <div className='mb-4'>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-2 text-center">
                                    <Form.Label ><h6 >Enter Code</h6></Form.Label>
                                    <div className='codeContainer'>
                                        <input type="number" name='code1' id='code1'
                                            className='p-1 text-center me-2 rounded-2 h5' onFocus={clearInputValue} onChange={focusNextInput('code2')} size={1} maxLength={1} style={{ width: '30px' }} autoFocus required />
                                        <input type="number" name='code2' id='code2'
                                            className='p-1 text-center me-2 rounded-2 h5' onFocus={clearInputValue} onChange={focusNextInput('code3')} size={1} maxLength={1} style={{ width: '30px' }} required />
                                        <input type="number" name='code3' id='code3'
                                            className='p-1 text-center me-2 rounded-2 h5' onFocus={clearInputValue} onChange={focusNextInput('code4')} size={1} maxLength={1} style={{ width: '30px' }} required />
                                        <input type="number" name='code4' id='code4'
                                            className='p-1 text-center me-2 rounded-2 h5' onFocus={clearInputValue} onChange={focusNextInput('code5')} size={1} maxLength={1} style={{ width: '30px' }} required />
                                        <input type="number" name='code5' id='code5'
                                            className='p-1 text-center me-2 rounded-2 h5' onFocus={clearInputValue} onChange={focusNextInput('code6')} size={1} maxLength={1} style={{ width: '30px' }} required />
                                        <input type="number" name='code6' id='code6'
                                            className='p-1 text-center rounded-2 h5' onFocus={clearInputValue} onChange={focusNextInput('verify')} size={1} maxLength={1} style={{ width: '30px' }} required />
                                    </div>
                                    {/* <div className='text-end'>
                                            <Form.Text className="justify-text-right "><a href='#' className='h6'>Resend Code</a></Form.Text>
                                        </div> */}
                                    <Button className="mt-4 w-50" type="submit" id='verify'>Verify</Button>
                                </Form.Group>
                            </Form>
                            <p className='text-danger mt-4 mb-0 pb-0'>&nbsp;{errorMessage}</p>
                            <h6 className='mt-4'>
                                Didn't receive the code?<br />
                                {isResendDisabled ? <small>Please wait {counter} seconds.</small> : <a href="#" onClick={requestOTP}>Resend OTP</a>}</h6>
                        </div>
                        <div className='pt-4'>
                            <a href={ROUTES.AGENT_LOGOUT_ROUTE} className='btn btn-outline-secondary'
                                style={{ width: '200px' }}
                            >Log me Out</a>
                        </div>
                    </Col>
                </Row>
            </Container >
        </>
    );
}

export default AgentValidateMobile;