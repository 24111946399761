import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import useUpdateMobileLogic from '../../components/members/UpdateMobileLogic';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'

const UpdateMobile: React.FC = () => {
    const {
        handleSubmit,
        errorMessage,
    } = useUpdateMobileLogic();

    return (
        <>
            <Container>
                <Row className='mt-5'>
                    <Col
                        lg={{ span: 4, offset: 4 }}
                        className="text-center mb-5">
                        <h1 className='mb-4'>Update <span className='highlightText px-2'>Mobile</span></h1>
                        <p className='h5'>Register your mobile number to proceed.</p>
                        <br />
                        <p className='h6'></p>
                    </Col>
                </Row>
                <Row>
                    <Col
                        lg={{ span: 4, offset: 4 }}
                        style={{ minHeight: '' }}
                        className='boxContainer p-5 text-center'>

                        <div className='mb-4'>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-2 text-center">
                                    <Form.Label ><h6 >Enter your mobile number below:</h6></Form.Label>
                                    <div className='codeContainer  text-start'>
                                        <input type="tel" name='mobilenumber' pattern="^(63\d{10})$" maxLength={12} className="form-control w-100" placeholder="Mobile Number" required />
                                        <p className='lh-1 '><small className='text-secondary'>Accepted format: 639xxxxxxxxx</small></p>
                                    </div>
                                    <h6 className='mt-4 text-warning'>Mobile Number is uneditable after submission. Make sure this is correct.</h6>
                                    <Button className="mt-4 w-50" type="submit" id='verify'>Submit</Button>
                                </Form.Group>
                            </Form>
                            <p className='text-danger mt-4 mb-0 pb-0'>&nbsp;{errorMessage}</p>
                        </div>
                    </Col>
                </Row>
            </Container >
        </>
    );
}

export default UpdateMobile;