import { useLocation } from 'react-router-dom';

import AgentContent from './AgentContent';
import TopNavbarAgent from 'pages/partials/agent/TopNavbarAgent';
import TopNavbarVerificationAgent from 'pages/partials/agent/TopNavbarVerificationAgent';
import { ROUTES } from '../resources/routes-constants';

const AgentLayout = () => {
    const location = useLocation();

    return (
        <div>
            {
                (location.pathname !== ROUTES.AGENT_VALIDATE_ROUTE && location.pathname !== ROUTES.AGENT_MOBILE_UPDATE_ROUTE) ?
                    <TopNavbarAgent />
                    :
                    <TopNavbarVerificationAgent />
            }
            <div className="body flex-grow-1 px-3">
                <AgentContent />
            </div>
        </div>
    )
}

export default AgentLayout
