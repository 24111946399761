import React from 'react'
import { Route, Routes } from 'react-router-dom'

// routes config
// import routes from '../routes'
import { ROUTES } from 'resources/routes-constants'

// main public pages
import NotFoundPage from 'pages/NotFoundPage'
// end main public pages


// member pages
import Authorize from 'pages/members/Authorize';
import ValidateMobile from 'pages/members/ValidateMobile';
import UpdateMobile from 'pages/members/UpdateMobile';
import Home from 'pages/members/Home'
import Deposit from 'pages/members/Deposit'
import Withdraw from 'pages/members/Withdraw'
import Deposits from 'pages/members/Deposits'
import Withdrawals from 'pages/members/Withdrawals'
import Tutorial from 'pages/members/Tutorial'
import Rules from 'pages/members/Rules'
import Contact from 'pages/members/Contact'
import Games from 'pages/members/Games'
import Play from 'pages/members/Play'
import Account from 'pages/members/Account'
import DepositHistory from 'pages/members/DepositHistory'
import WithdrawalHistory from 'pages/members/WithdrawlHistory'
import VerifyEmail from 'pages/members/VerifyEmail'
import Logout from 'pages/members/Logout'

// end member pages

const AppContent = () => {

  return (
    <Routes>
        <Route path="*" element={<NotFoundPage />} />
        <Route path={ROUTES.PLAYER_LOGOUT_ROUTE} element={<Logout />} />
        <Route path={ROUTES.AUTHORIZE_ROUTE} element={<Authorize />} />
        <Route path={ROUTES.VALIDATE_ROUTE} element={<ValidateMobile />} />
        <Route path={ROUTES.MOBILE_UPDATE_ROUTE} element={<UpdateMobile />} />
        <Route path={ROUTES.HOME_ROUTE} element={<Home />} />
        <Route path={ROUTES.PLAY_ROUTE} element={<Play />} />
        <Route path={ROUTES.GAMES_ROUTE + '/:id'} element={<Games />} />
        <Route path={ROUTES.DEPOSIT_ROUTE} element={<Deposit />} />
        <Route path={ROUTES.WITHDRAW_ROUTE} element={<Withdraw />} />
        <Route path={ROUTES.DEPOSITS_ROUTE + '/:id'} element={<Deposits />} />
        <Route path={ROUTES.WITHDRAWALS_ROUTE + '/:id'} element={<Withdrawals />} />
        <Route path={ROUTES.TUTORIAL_ROUTE} element={<Tutorial />} />
        <Route path={ROUTES.RULES_ROUTE} element={<Rules />} />
        <Route path={ROUTES.CONTACT_ROUTE} element={<Contact />} />
        <Route path={ROUTES.ACCOUNT_ROUTE} element={<Account />} />
        <Route path={ROUTES.DEPOSIT_HISTORY_ROUTE + '/:id'} element={<DepositHistory />} />
        <Route path={ROUTES.WITHDRAWAL_HISTORY_ROUTE + '/:id'} element={<WithdrawalHistory />} />
        <Route path={ROUTES.VERIFY_ROUTE} element={<VerifyEmail />} />

    </Routes>
  )
}

export default React.memo(AppContent)
