import React from 'react'
import { Route, Routes } from 'react-router-dom'

// routes config
// import routes from '../routes'
import { ROUTES } from 'resources/routes-constants'

// main public pages
import NotFoundPage from 'pages/NotFoundPage'
// end main public pages

// agent pages
import AgentValidateMobile from 'pages/agents/ValidateMobile';
import AgentUpdateMobile from 'pages/agents/UpdateMobile';
import AgentLogout from 'pages/agents/Logout';
import AgentHome from 'pages/agents/Home';
import AgentCreditsBuy from 'pages/agents/CreditsBuy';
import AgentWithdraw from 'pages/agents/Withdraw';
import AgentVerifyEmail from 'pages/agents/VerifyEmail';
import AgentWithdrawalRequestList from 'pages/agents/WithdrawalRequestList';
import AgentTransactionList from 'pages/agents/TransactionList';

import AgentCommissionConvert from 'pages/agents/CommissionConvert';
import AgentCommissionTransactionList from 'pages/agents/CommissionTransactionList';
import AgentStats from 'pages/agents/Stats';

import AgentDownlineList from 'pages/agents/DownlineList';
import AgentDownlineRequestList from 'pages/agents/DownlineRequestList';
import AgentDownlineTransactionList from 'pages/agents/DownlineTransactionList';

import AgentDownlineRequestView from 'pages/agents/DownlineRequestView';
import AgentDownlineTransactionView from 'pages/agents/DownlineTransactionView';

import AgentAccount from 'pages/agents/Account';
// end agent pages

const AgentContent = () => {
  return (
    <Routes>
      {/* {agentValidToken()} */}
        <Route path={ROUTES.AGENT_LOGOUT_ROUTE} element={<AgentLogout />} />
        <Route path={ROUTES.AGENT_VALIDATE_ROUTE} element={<AgentValidateMobile />} />
        <Route path={ROUTES.AGENT_MOBILE_UPDATE_ROUTE} element={<AgentUpdateMobile />} />
        <Route path={ROUTES.AGENT_HOME_ROUTE} element={<AgentHome />} />
        <Route path={ROUTES.AGENT_CREDITS_BUY_ROUTE} element={<AgentCreditsBuy />} />
        <Route path={ROUTES.AGENT_WITHDRAW_ROUTE} element={<AgentWithdraw />} />
        <Route path={ROUTES.AGENT_VERIFY_ROUTE} element={<AgentVerifyEmail />} />

        <Route path={ROUTES.AGENT_WITHRAWALS_ROUTE + '/:id'} element={<AgentWithdrawalRequestList />} />
        <Route path={ROUTES.AGENT_TRANSACTIONS_ROUTE + '/:id'} element={<AgentTransactionList />} />

        <Route path={ROUTES.AGENT_COMMISSION_CONVERT_ROUTE} element={<AgentCommissionConvert />} />
        <Route path={ROUTES.AGENT_COMMISSION_TRANSACTIONS_ROUTE + '/:id'} element={<AgentCommissionTransactionList />} />
        <Route path={ROUTES.AGENT_STATS_ROUTE + '/:id'} element={<AgentStats />} />

        <Route path={ROUTES.AGENT_DOWNLINES_ROUTE + '/:id'} element={<AgentDownlineList />} />
        <Route path={ROUTES.AGENT_DOWNLINE_REQUESTS_ROUTE + '/:id'} element={<AgentDownlineRequestList />} />
        <Route path={ROUTES.AGENT_DOWNLINE_TRANSACTIONS_ROUTE + '/:id'} element={<AgentDownlineTransactionList />} />
        <Route path={ROUTES.AGENT_DOWNLINE_REQUEST_VIEW_ROUTE + '/:id'} element={<AgentDownlineRequestView />} />
        <Route path={ROUTES.AGENT_DOWNLINE_TRANSACTION_VIEW_ROUTE + '/:id'} element={<AgentDownlineTransactionView />} />

        <Route path={ROUTES.AGENT_ACCOUNT_ROUTE} element={<AgentAccount />} />

        <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

export default React.memo(AgentContent)
