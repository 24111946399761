import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import { Dropdown, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faSearch } from '@fortawesome/free-solid-svg-icons';

import AdminCustomToast from '../../components/admin/AdminCustomToast';
import CustomPagination from '../../components/CustomPagination';

import AdminConfirmationModal from '../../components/admin/modals/AdminConfirmationModal';

import usePlayerListLogic from '../../components/admin/PlayerListLogic';
import { formatDate, formatDateWithTime, formatNumberToCurrency } from 'utility/functions';
import Loader from 'components/Loader';

const UsersPlayerList: React.FC = () => {
    const {
        showToast, handleCloseToast, showToastType,
        showConfirmation, handleConfirmationSubmit, handleCloseConfirmation, confirmationType, fullName, id,
        handleConfirmation, handleSearch, resetSearch, handleSearchInput, searchParam,
        post, total, pageNumber, showCloseButton,
        handleDropdown, dropdownText,
        loadingStatus
    } = usePlayerListLogic();

    return (
        <>
            <AdminCustomToast show={showToast} close={handleCloseToast} showToastType={showToastType} />
            <AdminConfirmationModal show={showConfirmation} submit={handleConfirmationSubmit} close={handleCloseConfirmation} confirmationType={confirmationType} fullName={fullName} userId={id} />

            <Container>
                <Row>
                    <Col
                        md={{ span: 8, offset: 2 }}
                        sm={{ span: 12 }}
                        className="text-center mb-5">
                        <h4>List of Players</h4>
                    </Col>
                </Row>
                {loadingStatus ?
                    <Loader />
                    :
                    <>
                        <Row className='mb-3'>
                            <Col
                                md={{ span: 10, offset: 1 }}
                                sm={{ span: 12 }}
                            >
                                <Row>

                                    <Col
                                        md={{ span: 3, offset: 5 }}
                                        sm={{ span: 12 }}
                                        className="text-end me-0 pe-0">
                                        <Dropdown className="justify-content-end me-0 pe-0 w-100">
                                            <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='w-100'>
                                                {dropdownText}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className='w-100'>
                                                <Dropdown.Item href="#" onClick={handleDropdown}>All</Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={handleDropdown}>Inactive</Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={handleDropdown}>Active</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                    <Col
                                        md={{ span: 4 }}
                                        sm={{ span: 12 }}
                                        className="text-end ms-0">
                                        <Form id='searchForm' className='w-100' onSubmit={handleSearch}>
                                            <Form.Group className="d-inline-flex justify-content-end ms-0 w-100">
                                                <Form.Control id="searchField" type='text' name='searchParam' placeholder="Search Name" className='w-100 ms-0 pe-5' value={searchParam} onChange={handleSearchInput} />
                                                <FontAwesomeIcon icon={faClose} className={'text-dark my-auto d-flex justify-content-end search-icon search-close-button ' + showCloseButton} onClick={resetSearch} />
                                                <FontAwesomeIcon icon={faSearch} className='text-dark my-auto d-flex justify-content-end ps-2' />
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row>
                            <Col
                                md={{ span: 10, offset: 1 }}
                                sm={{ span: 12 }}
                                className="text-center table-responsive">
                                <Table striped bordered hover variant="dark">
                                    <thead className='bg-dark'>
                                        <tr>
                                            <th>USERNAME</th>
                                            <th>NAME</th>
                                            <th>MOBILE NUMBER</th>
                                            <th>STATUS</th>
                                            <th>CREDITS</th>
                                            <th>LAST LOGIN</th>
                                            <th>ACTIONS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {post && post.data.map((player: any, count: any) => (
                                            <tr className='bg-dark p-5 text-white' key={player.id}>
                                                <td>{player.username}</td>
                                                <td>{player.name}</td>
                                                <td>{player.mobileNumber}</td>
                                                <td>
                                                    {player.status === 'active' ? <span className='text-success'>Active</span> : <span className='text-danger'>Inactive</span>}
                                                </td>
                                                <td><span className="text-warning">{formatNumberToCurrency(player.wallet.balance)}</span></td>
                                                {/* <td>{formatDateWithTime(player.lastLogin)}</td> */}
                                                <td>{formatDateWithTime(player.lastLogin)}</td>
                                                <td>
                                                    {player.status === 'active' ?
                                                        <a href='#' className='ms-1' onClick={() => handleConfirmation('deactivate-agent', player.name, player.id)}>Deactivate</a> :
                                                        <a href='#' className='ms-1' onClick={() => handleConfirmation('activate-agent', player.name, player.id)}>Activate</a>
                                                    }
                                                    <br />
                                                    <a href='#' className='ms-1' onClick={() => handleConfirmation('reset-player-password', player.name, player.id)}>Reset/Unblock</a>
                                                    <br />
                                                    <a href='#' className='ms-1 text-danger' onClick={() => handleConfirmation('change-player-email', player.name, player.id)}>Change Email</a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                md={{ span: 10, offset: 1 }}
                                sm={{ span: 12 }}
                                className="d-flex justify-content-end mb-5">
                                <CustomPagination post={post} total={total} current={pageNumber} />
                            </Col>
                        </Row>
                    </>
                }
            </Container >
        </>
    );
}

export default UsersPlayerList;

function updateFormData(arg0: any) {
    throw new Error('Function not implemented.');
}
