import { useState } from 'react';
import { API } from 'resources/api-constants';
import { ROUTES } from 'resources/routes-constants';
import { clientAxios } from 'utility/api/agent/axiosAgent';

const useAgentUpdateMobileLogic = () => {
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async function (e: any) {
        e.preventDefault();
        setErrorMessage('');

        //code
        const mobileNumber = e.target.mobilenumber.value;

        //call api
        await clientAxios.patch(
            API.AGENT_UPDATE_MOBILE_NUMBER,
            {
                mobileNumber: mobileNumber
            }
        ).then((response) => {
            setErrorMessage('Verifying');
            if (response.data.data.result === 'OK') {
                setErrorMessage('');
                localStorage.setItem('agent-send-otp', '1');

                window.location.replace(ROUTES.AGENT_VALIDATE_ROUTE);
            } else {
                setErrorMessage('An error has occured. Try again later.');
            }

        }).catch((error) => {
            //setErrorMessage('An error has occured. Try again later.');
           setErrorMessage(error.response.data.resource[0].msg);
        }).finally(() => {

        });
    }

    return {
        handleSubmit,
        errorMessage,
    };
}

export default useAgentUpdateMobileLogic;